<template>
  <div v-if="areGamesVisible" class="is-hidden-tablet container-header-mobile-matches">
    <div class="columns is-mobile" style="align-items: center">
      <div class="column is-2 no-padding" style="margin-left: 0.8rem">
        <BButton type="is-light" rounded icon-left="arrow-left-thick" @click.prevent="toBack">VOLVER</BButton>
      </div>
    </div>
    <div class="game-selector is-mobile">
      <template v-if="areGamesVisible">
        <div class="game-selector__text"><span class="label-bselect">Elige el partido:</span></div>
        <div class="">
          <BField type="is-info">
            <BSelect v-model="selectedGame" placeholder="Seleccione" type="is-info" expanded>
              <option v-for="game in availableGames" :key="game.game_id" :value="game">
                {{ game.name }}
              </option>
            </BSelect>
          </BField>
        </div>
      </template>
      <template v-else>
        <div class="flag-menu-hidden"></div>
      </template>
    </div>
  </div>
  <div v-else class="is-hidden-tablet container-header-mobile-matches">
    <div class="columns is-mobile" style="align-items: center; justify-content: space-between">
      <!--      <div class="column is-5 no-padding">-->
      <!--        <div v-if="tournamentSelected && tournamentSelected.logo" class="tournament__logo">-->
      <!--          <img :src="tournamentSelected.logo" alt="Tournament logo" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="column is-2 no-padding" style="margin-left: 0.8rem">
        <BButton
          v-if="isInnerComponent"
          type="is-light"
          rounded
          icon-right="window-close"
          @click.prevent="$emit('close')"
          >Cerrar</BButton
        >
        <BButton v-else type="is-light" rounded icon-left="arrow-left-thick" @click.prevent="toBack">VOLVER</BButton>
      </div>
      <div class="column is-3 no-padding">
        <!-- todo: this live button can be a component for desktop and mobile menus -->
        <button
          v-if="showLive"
          class="button is-rounded is-live-inactive live-match-identifier"
          :class="{ 'is-live-active': showLiveData }"
          @click="switchShowLiveData"
        >
          <BIcon pack="mdi" icon="circle" size="is-small" type="is-green" class="icon-live-identifier"> </BIcon>
          <span class="flag-movil-live">LIVE</span>
        </button>
      </div>

      <div class="column is-4">
        <BButton type="is-green" rounded icon-right="tune" class="advanced" @click="onClickAvanzado">
          AVANZADO
        </BButton>
      </div>
    </div>
    <div class="columns is-mobile filter-container-matches">
      <template v-if="menuOptionActive == 'stats'">
        <div class="column is-4 no-padding"><span class="label-bselect">Tipo de apuesta:</span></div>
        <div class="column is-7 ">
          <BField type="is-info">
            <BSelect
              placeholder="Seleccione"
              type="is-info"
              :value="selectedAnalysis"
              @input="updateSelectedAnalysis"
              @change.native="onChangeTipoApuesta"
              expanded
            >
              <option v-for="(option, index) in analysisTypeOptions" :key="index" :value="option">
                {{ option.name }}
              </option>
            </BSelect>
          </BField>
        </div>
        <div class="column is-1 no-padding">
          <div class="info-buttons" @click="onClickTipoDeApuestaVerInformacion" style="padding: 0 16px 0 0">
            <BIcon
              class="tips__top-bets__text__icon-info"
              icon="information-outline"
              :size="this.displayWidth < 525 ? 'is-small' : 'is-normal'"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flag-menu-hidden"></div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import options from '@/data/next/options';
import {
  btnVolver,
  clickSelectTipoDeApuesta,
  clickBtnAvanzado,
  clickEstadisticaTipoDeApuestaVerInformacion,
} from '@/utils/analytics';
import { BButton } from 'buefy/dist/esm/button';
import { BField } from 'buefy/dist/esm/field';
import { BIcon } from 'buefy/dist/esm/icon';
import { BSelect } from 'buefy/dist/esm/select';

export default {
  name: 'MobileMenuNextMatches',
  components: {
    BButton,
    BField,
    BIcon,
    BSelect,
  },
  props: {
    size: {
      type: Number,
      required: true,
    },
    availableGames: {
      type: Array,
      default: () => [],
    },
    areGamesVisible: {
      type: Boolean,
      default: false,
    },
    isInnerComponent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      analysisTypeOptions: options.analysisTypeOptions,
      selectedGame: {},
    };
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('general', ['tournamentSelected']),
    ...mapState('nextGeneral', ['showLiveData', 'selectedAnalysis', 'gameForAnalysis']),
    ...mapGetters('nextGeneral', ['showLive', 'gameId', 'menuOptionActive']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapGetters('general', ['getPreviewSectionAnalytics']),
  },
  watch: {
    availableGames: {
      deep: true,
      handler(newValue) {
        this.selectedGame = newValue[0];
      },
    },
    selectedGame: {
      deep: true,
      handler(newValue) {
        this.$emit('game-change', newValue);
      },
    },
    gameForAnalysis: {
      deep: true,
      handler(newValue) {
        if (this.availableGames && this.availableGames.length) {
          this.selectedGame = this.availableGames.find(game => game.game_id === newValue.game_id);
        }
      },
    },
  },
  created() {
    this.betsOptions = options.betsOptions;
  },
  beforeMount() {
    if (!this.selectedAnalysis.name) {
      this.$store.commit('nextGeneral/updateSelectedAnalysis', this.analysisTypeOptions[0]);
    }
  },
  methods: {
    ...mapMutations('nextGeneral', ['toggleShowLiveData', 'toggleAdvanceFilter', 'updateSelectedAnalysis']),
    ...mapMutations(['SET_PREMIUM_MESSAGE', 'setModalInfoVisible']),
    toBack() {
      btnVolver({ game_id: this.gameId, is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      let backUrl = null;
      if (this.$route.query.pages === 'calendar-gs-2') {
        backUrl += this.$route.query.pages;
      } else if (this.areGamesVisible) {
        backUrl += '#betTool';
      }
      if (backUrl) {
        this.$router.push(backUrl);
      } else {
        if (history.length > 1) {
          this.$router.back();
        } else {
          this.$router.push('/');
        }
      }
      this.$router.push(backUrl);
    },
    switchShowLiveData() {
      if (this.getPremiumAccount) this.toggleShowLiveData();
      else this.SET_PREMIUM_MESSAGE(true);
    },
    onChangeTipoApuesta(e) {
      let selected = e.target.selectedIndex;
      if (selected >= 0) {
        let selectedText = e.target[selected].text;
        clickSelectTipoDeApuesta(
          this.gameId,
          this.showLive,
          selectedText,
          this.getPremiumAccount,
          this.getPreviewSectionAnalytics,
          this.getIsGolstats,
        );
      }
    },
    onClickAvanzado() {
      clickBtnAvanzado(
        this.gameId,
        this.showLive,
        this.getPremiumAccount,
        this.getPreviewSectionAnalytics,
        this.getIsGolstats,
      );
      this.toggleAdvanceFilter();
    },
    onClickTipoDeApuestaVerInformacion() {
      clickEstadisticaTipoDeApuestaVerInformacion(
        this.getPremiumAccount,
        this.getPreviewSectionAnalytics,
        this.getIsGolstats,
      );
      this.setModalInfoVisible({
        isVisible: true,
        title: 'ESTADÍSTICAS',
        componentName: 'TypeOfBets',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.flag-movil-live {
  @media only screen and (max-width: 360px) {
    display: none;
  }
}
.mdi-circle {
  color: #77a405;
}
.flag-menu-hidden {
  width: 100%;
  height: 10px;
  background: white;
}
.advanced {
  width: 110px;
  height: 36px;
  border-radius: 8px;
  background-color: #7ed321 !important;
  color: white !important;
  letter-spacing: -0.03px;
  font-size: 11px;
}
.advanced:hover {
  background-color: #8ede3c !important;
  color: #076f07 !important;
}
.info-buttons {
  padding-top: 18px;
  display: flex;
  float: right;
  padding-right: 14px;
}
.live-match-identifier {
  margin-top: 0px;
  margin-right: -40px;
}
.filter-container-matches {
  align-items: center;
}
.no-padding {
  padding: 0px 0px !important;
}
.label-bselect {
  font-size: 1rem;
  font-family: Avenir-Pro-Bold, sans-serif;
}
.game-selector {
  display: grid;
  grid-template-columns: 8.75rem 1fr;
  margin-bottom: 1rem;

  &__text {
    align-self: center;
  }

  @media screen and (max-width: 360px) {
    grid-template-columns: 5.8rem 1fr;
  }
}
.container-header-mobile-matches {
  margin-bottom: 0.7rem !important;
}
.live-button {
  font-family: Circular-Std-Medium;
}
.is-hidden-tablet {
  margin-bottom: 1.5rem;
}

.is-live-inactive {
  color: white;
  background-color: #d8d8d8;
}
.is-live-inactive:hover {
  background-color: #b4abab;
}

.is-live-active {
  color: white;
  background-color: #282828;
  & > span.icon {
    color: #7ed321;
  }
}
.is-live-active:hover {
  color: #87fd03;
  background-color: #171616;
  & > span.icon {
    color: #87fd03;
  }
}
.toggle-advance-filter:hover {
  background-color: #dbdbdb;
}

.tournament__logo {
  height: 26px;

  & > img {
    height: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .advanced {
    float: right;
  }
}
@media only screen and (max-width: 360px) {
  .advanced {
    float: right;
  }
  .live-match-identifier {
    float: right;
    margin-right: 0px;
    width: 38px;
  }
  .icon-live-identifier {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media only screen and (max-width: 360px) {
  .label-bselect {
    font-size: 0.7rem;
    padding-top: 22px;
    padding-right: 0px;
  }
  .info-buttons {
    padding-top: 18px;
    display: flex;
    float: right;
    padding-right: 6px;
  }
}
</style>
